"use strict";

$('.slider-content').slick({
    prevArrow: $('.slider-arrows .prev'),
    nextArrow: $('.slider-arrows .next'),
    adaptiveHeight: true
});

$('.paroller').paroller();

$.localScroll({
    duration: 1600,
    offset: {
        top: 0
    }
});

var inst = $('.remodal').remodal();

$('.btn-get-catalog').on('click', function (e) {
    e.preventDefault();

    inst.open();
});

$('.modal-close').on('click', function (e) {
    e.preventDefault();
    inst.close();
});

function getRandom(min, max) {
    return Math.random() * (max - min) + min;
}


/*$(document).ready(function () {

    let blocks = [];
    let $lego = $('.lego');
    let legoContainerOffset = $lego.offset();
    let legoOffsetTop = legoContainerOffset.top;
    let legoOffsetLeft = legoContainerOffset.left;
    let legoContaierWidth = $lego.width();
    let legoContaierHeight = $lego.height();

    $('.lego-image').each(function (index) {

        let $element = $(this);
        let translateX = getRandom(60, legoContaierWidth - 60);
        let translateY = getRandom(60, legoContaierHeight - 60);

        let rotate = getRandom(0, 360);
        let el = {
            $: $element,
            translateX: translateX,
            translateY: translateY,
            height: $element.height(),
            width: $element.width(),
            rotate: rotate,
            blocked: false
        };
        blocks.push(el);

        $element.css('transform', 'translate(' + translateX + 'px, ' + translateY + 'px) rotate(' + rotate + 'deg)');
    });

    setInterval(function () {
        $.each(blocks, function (index, elem) {

            let elOffset = elem.$.offset();
            let elPosY = elOffset.top;
            let elPosX = elOffset.left;

            let translateX = getRandom(elem.translateX - getRandom(20, 60), elem.translateX + getRandom(20, 60));
            let translateY = getRandom(elem.translateY - getRandom(20, 60), elem.translateY + getRandom(20, 60));

            let rotate = getRandom(elem.rotate - getRandom(20, 40), elem.rotate + getRandom(20, 40));

            $.each(blocks, function (subIndex, subElem) {

                let subElOffset = subElem.$.offset();
                let subElPosY = subElOffset.top;
                let subElPosX = subElOffset.left;

                if (parseInt(subIndex) !== parseInt(index)
                    && !elem.blocked
                    && elPosY > subElPosY
                    && elPosY < subElPosY + subElem.height
                    && elPosX > subElPosX
                    && elPosX < subElPosX + subElem.width) {

                    let subTranslateX = getRandom(subElem.translateX - getRandom(20, 60), subElem.translateX + getRandom(20, 60));
                    let subTranslateY = getRandom(subElem.translateY - getRandom(20, 60), subElem.translateY + getRandom(20, 60));

                    let subRotate = getRandom(subElem.rotate - getRandom(20, 40), subElem.rotate + getRandom(20, 40));
                    elem.$.css('transform', 'translate(' + translateX + 'px, ' + translateY + 'px) rotate(' + rotate + 'deg)');
                    subElem.$.css('transform', 'translate(' + subTranslateX + 'px, ' + subTranslateY + 'px) rotate(' + subRotate + 'deg)');
                    elem.blocked = true;
                    subElem.blocked = true;

                    setTimeout(() => unlock(elem), 700);
                    setTimeout(() => unlock(subElem), 700);
                }

                if (parseInt(subIndex) !== parseInt(index)
                    && !elem.blocked
                    && elPosY + elem.height > subElPosY
                    && elPosY + elem.height < subElPosY + subElem.height
                    && elPosX > subElPosX
                    && elPosX < subElPosX + subElem.width) {

                    let subTranslateX = getRandom(subElem.translateX - getRandom(20, 60), subElem.translateX + getRandom(20, 60));
                    let subTranslateY = getRandom(subElem.translateY - getRandom(20, 60), subElem.translateY + getRandom(20, 60));

                    let subRotate = getRandom(subElem.rotate - getRandom(20, 40), subElem.rotate + getRandom(20, 40));
                    elem.$.css('transform', 'translate(' + translateX + 'px, ' + translateY + 'px) rotate(' + rotate + 'deg)');
                    subElem.$.css('transform', 'translate(' + subTranslateX + 'px, ' + subTranslateY + 'px) rotate(' + subRotate + 'deg)');
                    elem.blocked = true;
                    subElem.blocked = true;

                    setTimeout(() => unlock(elem), 700);
                    setTimeout(() => unlock(subElem), 700);
                }

                if (parseInt(subIndex) !== parseInt(index)
                    && !elem.blocked
                    && elPosY > subElPosY
                    && elPosY < subElPosY + subElem.height
                    && elPosX + elem.width > subElPosX
                    && elPosX + elem.width < subElPosX + subElem.width) {

                    let subTranslateX = getRandom(subElem.translateX - getRandom(20, 60), subElem.translateX + getRandom(20, 60));
                    let subTranslateY = getRandom(subElem.translateY - getRandom(20, 60), subElem.translateY + getRandom(20, 60));

                    let subRotate = getRandom(subElem.rotate - getRandom(20, 40), subElem.rotate + getRandom(20, 40));
                    elem.$.css('transform', 'translate(' + translateX + 'px, ' + translateY + 'px) rotate(' + rotate + 'deg)');
                    subElem.$.css('transform', 'translate(' + subTranslateX + 'px, ' + subTranslateY + 'px) rotate(' + subRotate + 'deg)');
                    elem.blocked = true;
                    subElem.blocked = true;

                    setTimeout(() => unlock(elem), 700);
                    setTimeout(() => unlock(subElem), 700);
                }

                if (parseInt(subIndex) !== parseInt(index)
                    && !elem.blocked
                    && elPosY + elem.height > subElPosY
                    && elPosY + elem.height < subElPosY + subElem.height
                    && elPosX + elem.width > subElPosX
                    && elPosX + elem.width < subElPosX + subElem.width) {

                    let subTranslateX = getRandom(subElem.translateX - getRandom(20, 60), subElem.translateX + getRandom(20, 60));
                    let subTranslateY = getRandom(subElem.translateY - getRandom(20, 60), subElem.translateY + getRandom(20, 60));

                    let subRotate = getRandom(subElem.rotate - getRandom(20, 40), subElem.rotate + getRandom(20, 40));
                    elem.$.css('transform', 'translate(' + translateX + 'px, ' + translateY + 'px) rotate(' + rotate + 'deg)');
                    subElem.$.css('transform', 'translate(' + subTranslateX + 'px, ' + subTranslateY + 'px) rotate(' + subRotate + 'deg)');
                    elem.blocked = true;
                    subElem.blocked = true;

                    setTimeout(() => unlock(elem), 700);
                    setTimeout(() => unlock(subElem), 700);
                }
            });
        });
    }, 1);

    $(document).mousemove(function (e) {
        var posX = e.pageX;
        var posY = e.pageY;

        if (posY > legoOffsetTop && posY < legoOffsetTop + legoContaierHeight) {
            $.each(blocks, function (index, elem) {

                let elOffset = elem.$.offset();
                let elPosY = elOffset.top;
                let elPosX = elOffset.left;

                if (!elem.blocked && posY > elPosY && posY < elPosY + elem.height && posX > elPosX && posX < elPosX + elem.width) {

                    let translateX = getRandom(elem.translateX - getRandom(20, 60), elem.translateX + getRandom(20, 60));
                    let translateY = getRandom(elem.translateY - getRandom(20, 60), elem.translateY + getRandom(20, 60));

                    let rotate = getRandom(elem.rotate - getRandom(20, 40), elem.rotate + getRandom(20, 40));
                    elem.$.css('transform', 'translate(' + translateX + 'px, ' + translateY + 'px) rotate(' + rotate + 'deg)');
                    elem.blocked = true;
                    elem.translateX = translateX;
                    elem.translateY = translateY;

                    /!*$.each(blocks, function(subIndex, subElem) {

                        let subElOffset = subElem.$.offset();
                        let subElPosY = subElOffset.top;
                        let subElPosX = subElOffset.left;

                        if (parseInt(subIndex) !== parseInt(index)
                            && !elem.blocked
                            && elPosY > subElPosY
                            && elPosY < subElPosY + subElem.height
                            && elPosX > subElPosX
                            && elPosX < subElPosX + subElem.width) {
                            console.log(subElem);

                            let subTranslateX = getRandom(subElem.translateX - getRandom(20, 60), subElem.translateX + getRandom(20, 60));
                            let subTranslateY = getRandom(subElem.translateY - getRandom(20, 60), subElem.translateY + getRandom(20, 60));

                            let subRotate = getRandom(subElem.rotate - getRandom(20, 40), subElem.rotate + getRandom(20, 40));
                            elem.$.css('transform', 'translate(' + translateX + 'px, ' + translateY + 'px) rotate(' + rotate + 'deg)');
                            subElem.$.css('transform', 'translate(' + subTranslateX + 'px, ' + subTranslateY + 'px) rotate(' + subRotate + 'deg)');
                            elem.blocked = true;
                            subElem.blocked = true;

                            setTimeout(() => unlock(elem), 700);
                            setTimeout(() => unlock(subElem), 700);
                        }

                        if (parseInt(subIndex) !== parseInt(index)
                            && !elem.blocked
                            && elPosY + elem.height > subElPosY
                            && elPosY + elem.height < subElPosY + subElem.height
                            && elPosX > subElPosX
                            && elPosX < subElPosX + subElem.width) {

                            console.log(subElem);

                            let subTranslateX = getRandom(subElem.translateX - getRandom(20, 60), subElem.translateX + getRandom(20, 60));
                            let subTranslateY = getRandom(subElem.translateY - getRandom(20, 60), subElem.translateY + getRandom(20, 60));

                            let subRotate = getRandom(subElem.rotate - getRandom(20, 40), subElem.rotate + getRandom(20, 40));
                            elem.$.css('transform', 'translate(' + translateX + 'px, ' + translateY + 'px) rotate(' + rotate + 'deg)');
                            subElem.$.css('transform', 'translate(' + subTranslateX + 'px, ' + subTranslateY + 'px) rotate(' + subRotate + 'deg)');
                            elem.blocked = true;
                            subElem.blocked = true;

                            setTimeout(() => unlock(elem), 700);
                            setTimeout(() => unlock(subElem), 700);
                        }

                        if (parseInt(subIndex) !== parseInt(index)
                            && !elem.blocked
                            && elPosY > subElPosY
                            && elPosY < subElPosY + subElem.height
                            && elPosX + elem.width > subElPosX
                            && elPosX + elem.width < subElPosX + subElem.width) {

                            console.log(subElem);

                            let subTranslateX = getRandom(subElem.translateX - getRandom(20, 60), subElem.translateX + getRandom(20, 60));
                            let subTranslateY = getRandom(subElem.translateY - getRandom(20, 60), subElem.translateY + getRandom(20, 60));

                            let subRotate = getRandom(subElem.rotate - getRandom(20, 40), subElem.rotate + getRandom(20, 40));
                            elem.$.css('transform', 'translate(' + translateX + 'px, ' + translateY + 'px) rotate(' + rotate + 'deg)');
                            subElem.$.css('transform', 'translate(' + subTranslateX + 'px, ' + subTranslateY + 'px) rotate(' + subRotate + 'deg)');
                            elem.blocked = true;
                            subElem.blocked = true;

                            setTimeout(() => unlock(elem), 700);
                            setTimeout(() => unlock(subElem), 700);
                        }

                        if (parseInt(subIndex) !== parseInt(index)
                            && !elem.blocked
                            && elPosY + elem.height > subElPosY
                            && elPosY + elem.height < subElPosY + subElem.height
                            && elPosX + elem.width > subElPosX
                            && elPosX + elem.width < subElPosX + subElem.width) {

                            console.log(subElem);

                            let subTranslateX = getRandom(subElem.translateX - getRandom(20, 60), subElem.translateX + getRandom(20, 60));
                            let subTranslateY = getRandom(subElem.translateY - getRandom(20, 60), subElem.translateY + getRandom(20, 60));

                            let subRotate = getRandom(subElem.rotate - getRandom(20, 40), subElem.rotate + getRandom(20, 40));
                            elem.$.css('transform', 'translate(' + translateX + 'px, ' + translateY + 'px) rotate(' + rotate + 'deg)');
                            subElem.$.css('transform', 'translate(' + subTranslateX + 'px, ' + subTranslateY + 'px) rotate(' + subRotate + 'deg)');
                            elem.blocked = true;
                            subElem.blocked = true;

                            setTimeout(() => unlock(elem), 700);
                            setTimeout(() => unlock(subElem), 700);
                        }

                        //elem.blocked = true;
                        setTimeout(() => unlock(elem), 700);
                    });*!/
                }
            });
        }
    });
});

function unlock(elem) {
    elem.blocked = false;
}*/

$('.modal-get-catalog').on('click', function (e) {
    e.preventDefault();

    let stat = true;

    let $name = $('.form-name');
    let $phone = $('.form-phone');
    let $companyName = $('.form-company-name');
    let $inn = $('.form-inn');
    let $email = $('.form-email');

    if ($name.val().leng < 5) {
        stat = false;
    }
    if ($phone.val().leng < 5) {
        stat = false;
    }
    if ($companyName.val().leng < 5) {
        stat = false;
    }
    if ($email.val().leng < 5) {
        stat = false;
    }

    if (stat) {
        $.ajax({
            url: '/check.php',
            type: 'POST',
            data: {
                name: $name.val(),
                phone: $phone.val(),
                company: $companyName.val(),
                email: $email.val()
            },
            dataType: 'JSON',
            success: function (data) {
                if (data.status) {
                    location.href = '/';
                } else {
                    alert(data.error);
                }
            }
        });
    }
});